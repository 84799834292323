import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeroSection, LargeSection } from "../components/containers"
import { H1, H2, H4, P } from "../components/typography"
import { FlDivider, InsDivider } from "../components/dividers"

const StudioPage = () => (
  <Layout>
    <SEO
      title="Studio"
      description="We’re built different. We dream big. Learn how we help redesign organizations into modern service providers and brands to deliver meaningful experiences. Concrezan is an independent brand experience and service design consultancy."
    />
    <HeroSection>
      <H4>Who We Are</H4>
      <H1>Brand experience, service design, and future innovation.</H1>
      <P>An independent brand experience and service design consultancy.</P>
    </HeroSection>
    <FlDivider />
    <LargeSection>
      <H4>Our Commitment</H4>
      <H2>We are Concrezan.</H2>
      <P>
        We help redesign organizations into modern service providers and brands
        to deliver meaningful experiences. We use strategy, design, and
        technology to deliver value to customers and lead organizations to
        future innovation.
      </P>
      <P>
        All that said, we help you thrive in shaping the new normal, take
        appropriate actions, and make decisive decisions through design.
        Together, we create a future where the value we provide is no longer
        derived from the products we build, but from the service that we perform
        for people.
      </P>
    </LargeSection>
    <FlDivider />
    <LargeSection>
      <H4>How We Do It</H4>
      <H2>No yea-sayers, only collaborators.</H2>
      <P>We’re built different. We dream big. We love collaborations.</P>
      <P>
        There are a lot of things that we are really good at, but there’s so
        much that we don’t know about our client’s business. So, instead of
        saying yes to everything, we collaborate with them, have many deep
        conversations, and dream big together.
      </P>
      <P>
        We are also multi-disciplinary. We get all sorts of specialists involved
        in our work from researchers to designers to engineers. They create room
        for us to experiment and refine as we figure out what is right for you.
      </P>
    </LargeSection>
    <InsDivider />
    <LargeSection>
      <H2>No pitching.</H2>
      <P>
        We don’t do pitches. We don’t like wasting time, neither do you. In our
        perspective, it’s the worse way to start a working relationship.
      </P>
      <P>
        With us, a new project starts with the proposal. We collaborate with our
        clients to understand their needs, shape the right team, figure out the
        appropriate budget, and set the project up for success. Together we
        create the proposal that makes sense.
      </P>
    </LargeSection>
    <InsDivider />
    <LargeSection>
      <H2>No presentations.</H2>
      <P>
        We don’t do presentations. Again, we don’t like wasting time, neither
        should you. We prefer going straight to work, test the viability of
        ideas, build prototypes and get real-time feedback from our clients. As
        we focus on quick iterations of our work, clients get a clear view of
        its progression.
      </P>
    </LargeSection>
  </Layout>
)

export default StudioPage
